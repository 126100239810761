<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-card>
        <v-card-title> Imposta Stampa Commerciale </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                outlined
                prepend-inner-icon="mdi-package-up"
                label="Lotto"
                :items="lotti"
                item-text="codice"
                item-value="codice"
                v-model="cod_lotto"
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="produttore(data.item.produttore).logo_src" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.codice"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="produttore(data.item.produttore).nome"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-html="data.item.documento"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="">
            <v-col cols="4">
              <v-autocomplete
                outlined
                prepend-inner-icon="mdi-spa"
                label="Prodotto"
                :items="prodotti"
                item-text="nome"
                item-value="cod"
                v-model="cod_prodotto"
                ref="prod_auto"
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <v-img :src="data.item.avatar_src" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nome"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-text-field
                type="number"
                outlined
                v-model="qta"
                label="Quantità"
                prepend-inner-icon="mdi-calculator-variant-outline"
                @focus="sel($event)"
                @keypress.enter="add()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" class="d-flex justify-center">
              <v-btn fab color="primary" dark @click="add()">
                <v-icon class=""> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <template v-for="prodotto in array_prodotti">
            <ProdEl :prodotto="prodotto" :key="prodotto.cod" @remove="remove" />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="center">
            <v-btn
              outlined
              color="green"
              :disabled="!send_ok"
              :loading="loading"
              @click="send()"
              >Invia</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="d_ok" width="400">
        <v-card>
          <v-card-title class="success--text">
            Prodotto Impostato
          </v-card-title>
          <v-card-text> Prodotto impostato correttamente </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="green" outlined @click="d_ok = false"> chiudi </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="d_err" width="400">
        <v-card>
          <v-card-title class="error--text"> Errore </v-card-title>
          <v-card-text> Errore di connessione </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="green" outlined v-on:click="d_ok = false">
              chiudi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ProdEl from "./Prod_element";
export default {
  components: {
    ProdEl,
  },
  data() {
    return {
      cod_prodotto: null,
      cod_lotto: null,
      cod_torba: null,
      loading: false,
      d_ok: false,
      d_err: false,
      qta: 0,
      array_prodotti: [],
      menu: false,
    };
  },
  computed: {
    ...mapGetters({
      torbe: "get_torbe",
      prodotti: "get_prodotti",
      lotti: "get_lotti",
      menu: false,
    }),
    send_ok() {
      return this.array_prodotti.length > 0 && this.cod_lotto != null;
    },
  },
  methods: {
    produttore(cod) {
      return this.$store.getters.get_fornitore_by_cod(cod);
    },
    add() {
      let prod = this.$store.getters.get_prodotto_by_cod(this.cod_prodotto);
      this.array_prodotti.push({ ...prod, qta: this.qta });
      this.$refs.prod_auto.focus();
    },
    remove(cod) {
      console.log(cod);
      this.array_prodotti = this.array_prodotti.filter((el) => el.cod != cod);
    },
    sel: (e) => {
      e.target.select();
    },
    send() {
      let url = this.$store.getters.get_api_root + "/setPrintCommercial";
      let auth = this.$store.getters.get_auth;
      this.loading = true;
      axios
        .post(
          url,
          {
            cod_lotto: this.cod_lotto,
            prodotti: this.array_prodotti,
          },
          {
            auth: auth,
          }
        )
        .then(() => {
          this.d_ok = true;
        })
        .catch(() => {
          this.d_err = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
