<template>
  <v-row>
    <v-col cols="4">
      <v-list-item>
        <v-list-item-avatar size="40">
          <v-img v-bind:src="prodotto.avatar_src"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ prodotto.nome }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ prodotto.nome_sc }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="1" class="d-flex align-center justify-center">
      <v-menu v-model="menu" offset-y bottom :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <span class="headline" v-on="on" style="cursor: pointer"
            >{{ prodotto.qta }}
          </span>
        </template>
        <v-card max-width="100">
          <v-card-text>
            <v-text-field
              type="number"
              label="qta"
              ref="qta_box"
              v-model="prodotto.qta"
              outlined
              @focus="sel($event)"
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-col>
    <v-col cols="1" class="d-flex align-center justify-center">
      <v-btn icon @click="remove_prod()">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    prodotto: Object,
  },
  data() {
    return {
      menu: false,
      qta: this.prodotto.qta,
    };
  },
  methods: {
    remove_prod() {
      this.$emit("remove", this.prodotto.cod);
    },
    sel: (e) => {
      e.target.select();
    },
  },
};
</script>