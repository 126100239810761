<template>
    <StampaMain/>
</template>

<script>
import StampaMain from '@/components/stampa_commercial/stampa_commercial_main'
export default {
    components:{
        StampaMain
    }
    
}
</script>